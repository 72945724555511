@import 'customizations/fonts.css';

html {
  height: 100%;
  width: 100%;

}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  overflow: scroll;
  position: relative;

}

@media only screen and (min-width: 600px) {
  html {
    display: flex;
  }
}

/* fontello (icons) */
@font-face {
  font-family: 'fontello';
  src: url('./assets/fontello.eot?14546286');
  src: url('./assets/fontello.eot?14546286#iefix') format('embedded-opentype'),
       url('./assets/fontello.woff2?14546286') format('woff2'),
       url('./assets/fontello.woff?14546286') format('woff'),
       url('./assets/fontello.ttf?14546286') format('truetype'),
       url('./assets/fontello.svg?14546286#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
