@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./fonts/Lato-Bold.woff2') format('woff2'),
       url('./fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/WorkSans-Regular.woff2') format('woff2'),
       url('./fonts/WorkSans-Regular.ttf') format('truetype');
}

body {
  font-family: 'Work Sans', sans-serif;
}
